export function convertToCSV(data: any) {
    if (!data || !data.length) return '';
    const headers = Object.keys(data[0]);
    const headerRow = headers.join(',');

    const rows = data.map((item: any) => {
        return headers.map(header => {
            const value = item[header] ? item[header].toString() : '';
            return `"${value.replace(/"/g, '""')}"`;
        }).join(',');
    });

    return [headerRow, ...rows].join('\n');
}

export function downloadCSV(csvData: any, fileName = 'data.csv') {
    if (!csvData) return;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}