import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import toast from 'react-hot-toast'
import ExamService from '../../services/exam.service'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'


interface IShiftModal {
    show: any
    handleClose: any
    params: any
    reload: any
}

const ShiftModal = ({ show, handleClose, params, reload }: IShiftModal) => {



    const [shifts, setShifts] = useState<any>([]);


    const handleUpdate = async () => {
        await ExamService.addShiftByExamId(params, shifts).then((res) => {
            if (res.status === 200) {
                toast.success("Shift created successfully");
                handleClose(false);
                setShifts([])
                reload();
            }
        }).catch((err) => {
            console.error(err);
            toast.error(JSON.stringify(err));
        })
    }

    const addShift = () => {
        setShifts([...shifts, {
            shiftName: "",
            startTime: new Date(),
            endTime: new Date(),
            biometricStartTime: new Date(),
            biometriEndTime: new Date(),
            isLive: false,
            isBiometricAllowed: false,
        }])
    }


    const deleteShift = (index: number) => {
        let curr_shifts: any[] = shifts;
        let len: number = curr_shifts.length;
        if (len > 0) setShifts(curr_shifts.slice(0, index));
    }


    const handleChangeData = (e: any, index: number) => {
        setShifts(shifts.map((data: any, i: number) => {
            if (i === index) {
                if (e.target.name === "isLive" || e.target.name === "isBiometricAllowed") {
                    data[e.target.name] = e.target.checked;
                } else {
                    data[e.target.name] = e.target.value;
                }
            }
            return data;
        }))
    }

    return (
        <Modal
            id='kt_modal_create_app'
            show={show}
            onHide={handleClose}
            size='lg'
            centered
            animation={true}
            backdrop={"static"}
        >
            <Modal.Header>
                <h6 className="text-muted fw-bold">Add Shifts</h6>
            </Modal.Header>
            <div className='d-flex justify-content-end align-items-center p-3'>
                <Button size="sm" onClick={addShift} >Add</Button>
            </div>
            <div className="p-2">
                {shifts && shifts.map((data: any, index: number) => {
                    return (
                        <Card className='bio-card'>
                            <Card.Body>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <label className='fw-bold xcn-text-12 text-secondary'>Shift Name</label>
                                    <Form.Control
                                        value={data.shiftName}
                                        className='mt-2 xcn-text-12'
                                        type="text"
                                        placeholder="Shift Name"
                                        name="shiftName"
                                        onChange={(e) => { handleChangeData(e, index) }}
                                    />
                                    <Row className="mt-2">
                                        <Col md>
                                            <label className='fw-bold xcn-text-12 text-secondary'>Start Time</label>
                                            <DatePicker
                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                showTimeSelect
                                                selected={data.startTime}
                                                name="startTime"
                                                onChange={(e) => { handleChangeData({ target: { name: "startTime", value: e } }, index) }}
                                                className="form-control xcn-text-12"
                                                minDate={new Date()}
                                            />
                                        </Col>
                                        <Col md>
                                            <label className='fw-bold xcn-text-12 text-secondary'>End Time</label>
                                            <DatePicker
                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                showTimeSelect
                                                name="endTime"
                                                selected={data.endTime || moment().toDate()}
                                                onChange={(e) => { handleChangeData({ target: { name: "endTime", value: e } }, index) }}
                                                className="form-control xcn-text-12"
                                                minDate={new Date()}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md>
                                            <label className='fw-bold xcn-text-12 text-secondary'>Biometric Start Time</label>
                                            <DatePicker
                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                showTimeSelect
                                                selected={data.biometricStartTime}
                                                name="biometricStartTime"
                                                onChange={(date: Date) => { handleChangeData({ target: { name: "biometricStartTime", value: date } }, index) }}
                                                className="form-control xcn-text-12"
                                                minDate={new Date()}
                                            />
                                        </Col>
                                        <Col md>
                                            <label className='fw-bold xcn-text-12 text-secondary'>Biometric End Time</label>
                                            <DatePicker
                                                dateFormat="yyyy/MM/dd h:mm aa"
                                                showTimeSelect
                                                name="biometriEndTime"
                                                selected={data.biometriEndTime}
                                                onChange={(date: Date) => { handleChangeData({ target: { name: "biometriEndTime", value: date } }, index) }}
                                                className="form-control xcn-text-12"
                                                minDate={new Date()}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="mt-2 d-flex gap-3">
                                        <Form.Group className="d-flex gap-3">
                                            <label className='fw-bold xcn-text-12 text-secondary'>Live</label>
                                            <Form.Switch
                                                checked={data.isLive}
                                                name="isLive"
                                                onChange={(e) => handleChangeData(e, index)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="d-flex gap-3">
                                            <label className='fw-bold xcn-text-12 text-secondary'>Live</label>
                                            <Form.Switch
                                                checked={data.isBiometricAllowed}
                                                name="isBiometricAllowed"
                                                onChange={(e) => handleChangeData(e, index)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <Button variant='outline-danger' className="xcn-text-12 ms-2" size="sm" onClick={() => deleteShift(index)} >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    )
                }
                )}
            </div>
            <Modal.Footer>
                <Button size="sm" variant='outline-primary' onClick={handleUpdate}>Add</Button>
                <Button size="sm" variant='secondary' onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ShiftModal