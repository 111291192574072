import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap';
import clsx from 'clsx';
import toast from 'react-hot-toast';
import { faTimesCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminService from '../../services/admin.service';
import DropzoneData from '../Dropzone/Dropzone';
import Select from 'react-select';


interface ICreateStudent {
    show: boolean
    handleClose: () => void
    examId: any
    shiftId: any
}


const CreateStudentModal = ({ show, handleClose, examId, shiftId }: ICreateStudent) => {

    const [studentData, setStudentData] = useState<any>();
    const [isActive, setIsActive] = useState<boolean>(false)
    const [studentPhoto, setStudentPhoto] = useState<any>();
    const [options, setSelectedOption] = useState<any>([
        { value: "Female", label: "Female" },
         { value: "Male", label: "Male" },
    ]);
    const [gender, setGender] = useState<any>({ value: "Male", label: "Male" },); 


    const handleChangeValue = (e: any) => {
        setStudentData({ ...studentData, [e.target.name]: e.target.value });
    }

    const handleUpload = async (files: any) => {
        setStudentPhoto(files[0])
    }
    const createStudent = async (e: any) => {
        let formData = new FormData();
        formData.append("name", studentData.name)
        formData.append("centerPreference", studentData.centerPreference)
        formData.append("gender", gender.value)
        formData.append("rollNo", studentData.rollNo)
        formData.append("city", studentData.city)
        formData.append("state", studentData.state)
        formData.append("zone", studentData.zone)
        formData.append("centerName", studentData.centerName)
        formData.append("courseName", studentData.courseName)
        formData.append("file", studentPhoto)
        e.preventDefault();
        setIsActive(true)
        await AdminService.createStudent(formData, examId, shiftId).then((res) => {
            if (res.status === 200) {
                toast.success("Student created successfully");
                handleClose();
                setIsActive(false);

            }
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={true}
            size="xl"
        >
            <Modal.Body>
                <div className='d-flex justify-content-between align-items-center'>
                    <h5 className='text-primary fw-bold'>Create Student</h5>
                    <div> <FontAwesomeIcon icon={faXmark} className='xcn-link-pointer' onClick={handleClose} /> </div>
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Student Name</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='name'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Gender</Form.Label>
                    <Select
                        options={options}
                        name="gender"
                        onChange={setGender}
                        defaultValue={gender}
                        // value={options.find(
                        //     (data: any) => data.value === gender
                        // )}
                    />
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Roll Number</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='rollNo'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div> 
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>City</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='city'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                 <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Zone</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='zone'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>State</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='state'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Center Name</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='centerName'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Course Name</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='courseName'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Center Id</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='centerPreference'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Student Photo</Form.Label>
                    <div
                        className='border border-lg p-5 d-flex flex-column align-items-center'
                    >
                        <DropzoneData
                            handleFileUpload={handleUpload}
                        />
                    </div>
                </div>
                
                <Button size="sm" className='btn btn-primary mt-3' onClick={createStudent} disabled={isActive}>
                    Create
                </Button>
            </Modal.Body>
        </Modal>
    )
}

export default CreateStudentModal