import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Navbar, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import AdminService from '../../../services/admin.service'
import Select from "react-select"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint, faGear } from '@fortawesome/free-solid-svg-icons';
import FilterTable from '../../../components/table/filter.table';
import { UsersCard } from '../../../components/Card/Statistics.card';
import { faUser } from '@fortawesome/free-regular-svg-icons';

const Filter = () => {

    const [exam, setExam] = useState<any>();
    const [shifts, setShifts] = useState<any>();

    const [selectedExam, setSelectedExam] = useState<any>();
    const [selectedShift, setSelectedShift] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [centers, setCenters] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();


    const [tableData, setTableData] = useState<any>();



    const examName = async () => {
        await AdminService.getAllExamName().then((res) => {
            if (res.status === 200) {
                setExam(res.data.data.examName.map((data: any) => {
                    return {
                        value: data._id,
                        label: data.name
                    }
                }))
            }
        })
    }


    const getShiftData = async () => {
        await AdminService.getShiftData(selectedExam.value)
            .then((res) => {
                if (res.status === 200) {
                    setShifts(
                        res.data.data.shifts.map((data: any) => {
                            return {
                                value: data._id,
                                label: data.shiftName,
                            };
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getZones = async () => {
        await AdminService.getExamZones(selectedExam.value, selectedShift.value).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        })
    }

    const getDistricts = async () => {
        await AdminService.getExamDistricts(selectedExam.value, selectedShift.value).then(res => {
            if (res.status === 200) {
                setDistricts(res.data.map((dist: any) => {
                    return {
                        value: dist,
                        label: dist
                    }
                }))
            }
        })
    }

    const getCenters = async () => {
        await AdminService.getExamCenters(selectedExam.value, selectedShift.value).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((dist: any) => {
                    return {
                        value: dist,
                        label: dist
                    }
                }))
            }
        })
    }

    const getStates = async () => {
        await AdminService.getExamStates(selectedExam.value, selectedShift.value).then(res => {
            if (res.status === 200) {
                setStates(res.data.map((dist: any) => {
                    return {
                        value: dist,
                        label: dist
                    }
                }))
            }
        })
    }

    const handleGetExamData = async () => {
        if (selectedExam && selectedShift) {
            getZones();
            getDistricts();
            getCenters();
            getStates();
        }
    }


    const getTableData = async () => {
        const payload = {
            zone: selectedZones.map((zone: any) => zone.value),
            district: selectedDistricts.map((dist: any) => dist.value),
            centers: selectedCenters.map((center: any) => center.value),
            state: selectedStates.map((state: any) => state.value)
        }
        await AdminService.getStatsForFilterCombination(selectedExam.value, selectedShift.value, payload).then(res => {
            if (res.status === 200) {
                setTableData(res.data)
            }
        })
    }



    useEffect(() => {
        examName();
    }, [])

    useEffect(() => {
        if (selectedExam) {
            getShiftData();
        }
    }, [selectedExam]);


    console.log(tableData)

    return (
        <>
            <Navbar bg="light" expand="lg" className="navbarWrapper">
                <Container fluid>
                    <Navbar.Brand href="#">Live Record</Navbar.Brand>
                    <div className="d-flex gap-4">
                        <Select
                            options={exam}
                            onChange={setSelectedExam}
                            value={selectedExam}
                            placeholder="Select Exam"
                        />
                        <Select
                            options={shifts}
                            onChange={setSelectedShift}
                            value={selectedShift}
                            placeholder="Select Shift"
                        />
                        <Button onClick={handleGetExamData}>Fetch Data</Button>
                    </div>
                </Container>
            </Navbar>
            {tableData &&
                <div className="d-flex justify-content-center align-items-center">
                    <Row>
                        <Col md={6}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                                }
                                header="Users"
                                statsValue={tableData && tableData?.users?.length}
                            />
                        </Col>{" "}
                        <Col md={6}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faFingerprint} className="fs-1 text-muted" />
                                }
                                header="Verifications"
                                statsValue={tableData && tableData?.verifications?.length}
                            />
                        </Col>{" "}
                    </Row>
                </div>}
            <div>
                <Card className="shadow p-3 mb-3 mt-5 bg-white rounded">
                    <Card.Body>
                        <Row>
                            <Col>
                                <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                                    <Card.Body>
                                        <h4>Zones</h4>
                                        <Select
                                            options={zones}
                                            onChange={setSelectedZones}
                                            value={selectedZones}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                                    <Card.Body>
                                        <h4>Centers</h4>
                                        <Select
                                            options={centers}
                                            onChange={setSelectedCenters}
                                            value={selectedCenters}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                                    <Card.Body>
                                        <h4>District</h4>
                                        <Select
                                            options={districts}
                                            onChange={setSelectedDistricts}
                                            value={selectedDistricts}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                                    <Card.Body>
                                        <h4>State</h4>
                                        <Select
                                            options={states}
                                            onChange={setSelectedStates}
                                            value={selectedStates}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Button onClick={getTableData}>
                            <FontAwesomeIcon icon={faGear} className="me-2" />
                            Get Records
                        </Button>
                    </Card.Body>
                </Card>
            </div>
            <div>
                {tableData &&
                    <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                        <Card.Body>
                            <FilterTable tableData={tableData} />
                        </Card.Body>
                    </Card>
                }
            </div>

        </>
    )
}

export default Filter