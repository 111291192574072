import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export default class PublicService {
    static async userLogin(username: any, password: any) {
        return makeRequest(urls.public.userLogin, "post", {
            userName: username,
            password: password
        });
    }

    static async getUniqueCenters(shiftId: any) {
        return makeRequest(urls.getUniqueCenters + "/" + shiftId, "get");
    }

    static async addInstructor(instructor: any) {
        return makeRequest(urls.addInstructor, "post", instructor);
    }
}