import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import ExamService from '../../services/exam.service'
import { useParams } from 'react-router'
import toast from 'react-hot-toast'

interface IProps {
    show: boolean
    handleClose: () => void,
    shift: any,
    reload: any
}

export default function EditShiftModal({ show, handleClose, shift, reload }: IProps) {
    const [data, setData] = useState<any>(null);
    let { examId } = useParams();

    function handleChangeData(e: any) {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    async function handleSave() {
        let { _id, ...shiftData } = data;
        await ExamService.updateShiftByExamId(examId, _id, shiftData)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Shift updated successfully");
                    handleClose();
                    reload();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }


    useEffect(() => {
        setData(shift);
    }, [show]);
    return (
        <>
            <Modal
                id='kt_modal_create_app'
                show={show}
                onHide={handleClose}
                size='lg'
                centered
                animation={true}
                backdrop={"static"}
            >
                <Modal.Header>
                    <h6 className="text-muted fw-bold">Update Shift</h6>
                </Modal.Header>

                <Card className='bio-card'>
                    <Card.Body>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <label className='fw-bold xcn-text-12 text-secondary'>Shift Name</label>
                            <Form.Control
                                value={data?.shiftName}
                                className='mt-2 xcn-text-12'
                                type="text"
                                placeholder="Shift Name"
                                name="shiftName"
                                onChange={(e) => { handleChangeData(e) }}
                            />
                            <Row className="mt-2">
                                <Col md>
                                    <label className='fw-bold xcn-text-12 text-secondary'>Start Time</label>
                                    <DatePicker
                                        dateFormat="yyyy/MM/dd h:mm aa"
                                        showTimeSelect
                                        selected={moment(data?.startTime).toDate()}
                                        name="startTime"
                                        onChange={(e) => { handleChangeData({ target: { name: "startTime", value: e } }) }}
                                        className="form-control xcn-text-12"
                                        minDate={new Date()}
                                    />
                                </Col>
                                <Col md>
                                    <label className='fw-bold xcn-text-12 text-secondary'>End Time</label>
                                    <DatePicker
                                        dateFormat="yyyy/MM/dd h:mm aa"
                                        showTimeSelect
                                        name="endTime"
                                        selected={moment(data?.endTime).toDate()}
                                        onChange={(e) => { handleChangeData({ target: { name: "endTime", value: e } }) }}
                                        className="form-control xcn-text-12"
                                        minDate={new Date()}
                                    />
                                </Col>
                            </Row>
                            <Row>

                                <Col md>
                                    <label className='fw-bold xcn-text-12 text-secondary'>Biometric Start Time</label>
                                    <DatePicker
                                        dateFormat="yyyy/MM/dd h:mm aa"
                                        showTimeSelect
                                        selected={moment(data?.biometricStartTime).toDate()}
                                        name="biometricStartTime"
                                        onChange={(date: Date) => { handleChangeData({ target: { name: "biometricStartTime", value: date } }) }}
                                        className="form-control xcn-text-12"
                                        minDate={new Date()}
                                    />
                                </Col>
                                <Col md>
                                    <label className='fw-bold xcn-text-12 text-secondary'>Biometric End Time</label>
                                    <DatePicker
                                        dateFormat="yyyy/MM/dd h:mm aa"
                                        showTimeSelect
                                        name="biometriEndTime"
                                        selected={moment(data?.biometriEndTime).toDate()}
                                        onChange={(date: Date) => { handleChangeData({ target: { name: "biometriEndTime", value: date } }) }}
                                        className="form-control xcn-text-12"
                                        minDate={new Date()}
                                    />
                                </Col>
                            </Row>
                            <div className="mt-2 d-flex gap-3">
                                <Form.Group className="d-flex gap-3">
                                    <label className='fw-bold xcn-text-12 text-secondary'>Live</label>
                                    <Form.Switch
                                        checked={data?.isLive}
                                        name="isLive"
                                        onChange={(e) => setData({ ...data, isLive: e.target.checked })}
                                    />
                                </Form.Group>
                                <Form.Group className="d-flex gap-3">
                                    <label className='fw-bold xcn-text-12 text-secondary'>Biometric Allowed</label>
                                    <Form.Switch
                                        checked={data?.isBiometricAllowed}
                                        name="isBiometricAllowed"
                                        onChange={(e) => setData({ ...data, isBiometricAllowed: e.target.checked })}
                                    />
                                </Form.Group>
                            </div>
                        </Form.Group>
                    </Card.Body>
                </Card>
                <Modal.Footer>
                    <Button size="sm" variant='outline-primary' onClick={handleSave} >Save</Button>
                    <Button size="sm" variant='secondary' onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}