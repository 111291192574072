import moment from 'moment';
import React, { useEffect } from 'react'
import { Button, ButtonGroup, Card, Form, Modal, SplitButton, Table } from 'react-bootstrap';
import AdminService from '../../../services/admin.service';
import toast from 'react-hot-toast';
import { FaDownload } from 'react-icons/fa';


export default function ExportRequests() {

    const [versions, setVersions] = React.useState<any>([]);

    const getVersions = async () => {
        await AdminService.getExportRequests()
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data, "exports");
                    setVersions(res.data.exports || []);
                }
            }
            ).catch((err) => {
                console.log(err);
            }
            )
    }


    useEffect(() => {
        getVersions();
    }, [])

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Export Requests</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Progress</th>
                                <th>Exam</th>
                                <th>Shift</th>
                                <th>Requested By</th>
                                <th>Created At</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {versions && versions.map((items: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{items?.type || 'N/A'}</td>
                                        <td>{items?.progress || 'N/A'}</td>
                                        <td>{items?.examId?.name || 'N/A'}</td>
                                        <td>{items?.examId?.shifts?.find((shift: any) => shift?._id === items?.shiftId)?.shiftName || 'N/A'}</td>
                                        <td>{items?.requestedBy?.username || 'N/A'}</td>
                                        <td>{moment(items?.createdAt).format("DD-MM-YYYY hh:mm a")}</td>
                                        <td>
                                            {
                                                items?.link ?
                                                    <a href={items?.link} target="_blank" rel="noopener noreferrer">
                                                        <FaDownload />
                                                    </a> :
                                                    "N/A"
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}