import { useEffect } from "react";
import { useState } from "react";
import { Modal, Offcanvas, Form, Button } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import Select from 'react-select';
import PublicService from "../../services/public.service";


interface IAddInstructorModal {
    show: boolean
    handleClose: () => void
    reload: () => void
}
export default function AddInstructorModal({ show, handleClose, reload }: IAddInstructorModal) {
   const {examId, shiftId} = useParams();

   const [uniqueCenters, setUniqueCenters] = useState<any[]>([]);
   const [selectedCenter, setSelectedCenter] = useState<any>(null);

   const [instructor, setInstructor] = useState<any>();

   const getUniqueCenters = async () => {
    await PublicService.getUniqueCenters(shiftId).then((res) => {
        setUniqueCenters(res.data.centers?.map((center: any) => ({ label: center.allotedCenterId, value: center.centerPreference })));
    });
   }

   const addInstructor = async () => {
    const payload = {
        centerId: selectedCenter,
        examId: examId,
        shiftId: shiftId,
        operatorName: instructor?.operatorName,
        operatorEmail: instructor?.operatorEmail,
        operatorId: instructor?.operatorId,
    }
    await PublicService.addInstructor(payload).then((res) => {
        handleClose();
        reload();
    });
   }

   useEffect(() => {
    getUniqueCenters();
   }, []);
   
    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            animation={true}
            placement="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Instructor</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className = "fw-bold">Select Center</Form.Label>
                   <Select options={uniqueCenters} onChange={(e) => setSelectedCenter(e.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className = "fw-bold">Operator ID</Form.Label>
                    <Form.Control type="text" value={instructor?.operatorId} onChange={(e) => setInstructor({...instructor, operatorId: e.target.value})} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className = "fw-bold">Operator Name</Form.Label>
                    <Form.Control type="text" value={instructor?.operatorName} onChange={(e) => setInstructor({...instructor, operatorName: e.target.value})} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className = "fw-bold">Operator Email</Form.Label>
                    <Form.Control type="text" value={instructor?.operatorEmail} onChange={(e) => setInstructor({...instructor, operatorEmail: e.target.value})} />
                </Form.Group>

                <Button variant="primary" className = "w-100" onClick={addInstructor}>Add Instructor</Button>
            </Offcanvas.Body>
        </Offcanvas>
    )
}