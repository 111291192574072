import React, { useEffect, useState } from 'react';
import AdminService from '../../../services/admin.service';
import Select from 'react-select';
import { Table, Card } from 'react-bootstrap';
import TablePagination from '../../../components/Pagination/Table.paginaition';

export default function Invigilator() {
    const [exam, setExam] = useState<{ value: string; label: string }[]>([]);
    const [shifts, setShifts] = useState<{ value: string; label: string }[]>([]);
    const [selectedExam, setSelectedExam] = useState<{ value: string; label: string } | null>(null);
    const [selectedShift, setSelectedShift] = useState<{ value: string; label: string } | null>(null);
    const [invigilatorsData, setInvigilatorsData] = useState<any[]>([]);

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);

    const getExams = async () => {
        try {
            const res = await AdminService.getAllExamName();
            if (res.status === 200) {
                const formattedExams = res.data.data.examName.map((data: any) => ({
                    value: data._id,
                    label: data.name,
                }));
                setExam(formattedExams);
            }
        } catch (error) {
            console.error('Error fetching exams:', error);
        }
    };

    const getShifts = async () => {
        if (!selectedExam) return;
        try {
            const res = await AdminService.getShiftData(selectedExam.value);
            if (res.status === 200) {
                const formattedShifts = res.data.data.shifts.map((data: any) => ({
                    value: data._id,
                    label: data.shiftName,
                }));
                setShifts(formattedShifts);
            }
        } catch (error) {
            console.error('Error fetching shifts:', error);
        }
    };

    const getInvigilatorLogs = async () => {
        if (!selectedExam || !selectedShift) return;
        try {
            const res = await AdminService.getInvigilatorLogs(
                selectedExam.value,
                selectedShift.value,
                pageNumber,
                pageSize
            );
            if (res.status === 200) {
                setInvigilatorsData(res.data.data.login_logs);
                setTotal(res.data.data.total || 0); 
            }
        } catch (error) {
            console.error('Error fetching invigilator logs:', error);
        }
    };

    useEffect(() => {
        getExams();
    }, []);

    useEffect(() => {
        setShifts([]); 
        setSelectedShift(null);
        if (selectedExam) {
            getShifts();
        }
    }, [selectedExam]);

    useEffect(() => {
        setInvigilatorsData([]); 
        if (selectedExam && selectedShift) {
            getInvigilatorLogs();
        }
    }, [selectedExam, selectedShift, pageNumber, pageSize]);

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h5>Invigilators Logs</h5>
                <div className="d-flex gap-2">
                    <Select options={exam} value={selectedExam} onChange={setSelectedExam} placeholder="Select Exam" />
                    <Select options={shifts} value={selectedShift} onChange={setSelectedShift} placeholder="Select Shift" />
                </div>
            </div>
            <div className="mt-3">
                <InvigilatorTable data={invigilatorsData} />
            </div>
            <div className="text-center mt-2">
                <TablePagination
                    total={total}
                    currentPage={pageNumber}
                    perPage={pageSize}
                    handlePageChange={(e: number) => setPageNumber(e)}
                    setPerPage={(e: number) => setPageSize(e)}
                />
            </div>
        </div>
    );
}

// Table Component
const InvigilatorTable = ({ data }: { data: any[] }) => {
    return (
        <Card>
            <Card.Body>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th className="text-center">S.No</th>
                            <th className="text-center">Operator ID</th>
                            <th className="text-center">Operator Name</th>
                            <th className="text-center">Username</th>
                            <th className="text-center">Login Image</th>
                            <th className="text-center">GPS Coordinates</th>
                            <th className="text-center">Fingerprint</th>
                            <th className="text-center">Uploaded Login</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((invigilator: any, index: number) => (
                                <tr key={invigilator?._id}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{invigilator?.invigilatorId?.operatorId || 'N/A'}</td>
                                    <td className="text-center">{invigilator?.invigilatorId?.operatorName || 'N/A'}</td>
                                    <td className="text-center">{invigilator?.invigilatorId?.userName || 'N/A'}</td>
                                    <td className="text-center">
                                        {invigilator?.photo ? (
                                            <img src={invigilator.photo} alt="Login" width="50" height="50" />
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {invigilator?.gps ? (
                                            <span className="text-success">Uploaded</span>
                                        ) : (
                                            <span className="text-danger">Not Uploaded</span>
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {invigilator?.fingerprint ? (
                                            <span className="text-success">Uploaded</span>
                                        ) : (
                                            <span className="text-danger">Not Uploaded</span>
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {invigilator?.updatedAt ? new Date(invigilator.updatedAt).toLocaleString() : 'N/A'}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} className="text-center">
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};
