import { totalmem } from 'os'
import React, { useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'


interface IEditSetting {
    show: boolean
    handleClose: () => void
    enumsData: any
    settings: any
    handleValue: any
    handleSubmission: any
}


const EditSettingModel = ({ show, handleClose, enumsData, settings, handleValue, handleSubmission }: IEditSetting) => {


    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <Modal.Header className="fw-bold h5" >Edit Exam Settings</Modal.Header>
            <Modal.Body>
            <div className='d-flex align-item-center fv-row mt-3 mb-2 p-3'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>
                                <span className="fw-bold">Auth Type</span>
                            </th>

                            <th>
                                <span className="fw-bold">Allowed</span>
                            </th>

                            <th>
                                <span className="fw-bold">Required</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {enumsData && enumsData.length > 0 ? enumsData.map((data: any) => {
                            const enum_val = settings?.find((setting: any) => setting.method == data);
                            return (
                                <tr>
                                    <td><span className="fw-bold text-capitalize">{data === "fingerprint" ? "Right Fingerprint" : data === "fingerprint2" ? "Left Fingerprint" : data}</span></td>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            name="allowed"
                                            defaultChecked={enum_val && enum_val.allowed}
                                            style={{ marginLeft: "20px" }}
                                            onChange={(e: any) => handleValue(e, data)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            name="forceRequired"
                                            defaultChecked={enum_val && enum_val.forceRequired}
                                            style={{ marginLeft: "20px" }}
                                            onChange={(e: any) => handleValue(e, data)}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                            : ''}
                    </tbody>
                </Table>
            </div>
            </Modal.Body>
           <Modal.Footer>
            <Button variant="success"  onClick={handleSubmission}>Update</Button>
           </Modal.Footer>
        </Modal>
    )
}

export default EditSettingModel