import { faCheck, faCheckCircle, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import VerifiedUserModal from '../Modal/VerifiedUser.modal'

interface IFilterTable {
    tableData: any
}

const FilterTable = ({ tableData }: IFilterTable) => {

    const [showModal, setShowModal] = useState<any>(undefined);
    const [modalIndex, setModalIndex] = useState<number>(-1);

    console.log(tableData, "tableData")


    return (
        <>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Email</th>
                        <th>Photo</th>
                        <th>Roll.No</th>
                        <th>Verification</th>
                        <th>Mobile</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Center_Preference</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData ? tableData?.users?.map((data: any, index: number) => {
                        const verfiedUsers = tableData && tableData.verifications.find((verified: any) => verified.studentId === data._id);
                        return (
                            <>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td onClick={() => { setShowModal(data._id) }} className="cursor-pointer">{data && data.email ? data.email : "--"}</td>
                                    <td>{data && data.avatar ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />}</td>
                                    <td>{data && data.rollNo ? data.rollNo : "--"}</td>
                                    <td>{verfiedUsers ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />}</td>
                                    <td>{data && data.mobileNo ? data.mobileNo : "--"}</td>
                                    <td>{data && data.state ? data.state : "--"}</td>
                                    <td>{data && data.city ? data.city : "--"}</td>
                                    <td>{data && data.centerPreference ? data.centerPreference : "--"}</td>
                                </tr>
                            </>
                        )
                    }) : "No data found"}
                </tbody>
            </Table>
            <VerifiedUserModal
                show={showModal ? true : false}
                handleClose={() => setShowModal(undefined)}
                modalData={
                    tableData && tableData?.verifications?.find((verified: any) => verified.studentId === showModal)
                }
            />
        </>
    )
}

export default FilterTable