import moment from 'moment';
import React, { useContext, useEffect } from 'react'
import { Button, ButtonGroup, Card, Form, Modal, SplitButton, Table } from 'react-bootstrap';
import AdminService from '../../../services/admin.service';
import toast from 'react-hot-toast';
import { LoaderContext } from '../../../Context';
import { FaClipboard } from 'react-icons/fa';


export default function VersionsIndex() {

    const [versions, setVersions] = React.useState<any>([]);
    const [show, setShow] = React.useState<boolean>(false);
    const [showUpdate, setShowUpdate] = React.useState<any>();
    const [fileLink, setFileLink] = React.useState<string>("");
    const { loading, setLoading }: any = useContext(LoaderContext);


    const [versionInput, setVersionInput] = React.useState<string>("");

    const getVersions = async () => {
        await AdminService.getVersions().then((res) => {
            if (res.status === 200) {
                setVersions(res.data.versions);
            }
        }
        ).catch((err) => {
            console.log(err);
        }
        )
    }

    const create = async () => {
        if (!versionInput || !fileLink) {
            toast.error("Please enter version number and file link");
            return;
        }

        await AdminService.createVersion(versionInput, fileLink).then((res) => {
            if (res.status === 200) {
                toast.success("Version created successfully");
                setShow(false);
                getVersions();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Error in creating version");
        })
            .finally(() => {
                setVersionInput("");
                setFileLink("");
            })
    }

    const update = async () => {
        if (!fileLink) {
            toast.error("Please upload file");
            return;
        }

        await AdminService.updateFile(showUpdate?._id, fileLink)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("File updated successfully");
                    setShowUpdate(null);
                    getVersions();
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Error in updating file");
            })
            .finally(() => {
                setVersionInput("");
                setFileLink("");
            })
    }

    const markObsolete = async (id: string) => {
        await AdminService.markObsolete(id).then((res) => {
            if (res.status === 200) {
                toast.success("Version marked as obsolete");
                getVersions();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Error in marking version as obsolete");
        })
    }

    const markCurrent = async (id: string) => {
        await AdminService.markCurrent(id).then((res) => {
            if (res.status === 200) {
                toast.success("Version marked as current");
                getVersions();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Error in marking version as current");
        })
    }

    async function handleFileUpload(e: any) {
        if (!e.target.files) return;
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('upload', file);
        setLoading(true);
        await AdminService.uploadFile(formData).then((res) => {
            if (res.status === 200) {
                toast.success("File uploaded successfully");
                // getVersions();
                if (res.data?.links?.[0]) {
                    setFileLink(res.data?.links?.[0]);
                }
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Error in uploading file");
        })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        getVersions();
    }, [])

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header>
                    <Modal.Title>Create Version</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Version Number</Form.Label>
                        <Form.Control
                            type="text" placeholder="Enter version number"
                            value={versionInput}
                            onChange={(e) => setVersionInput(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>File</Form.Label>
                        <Form.Control
                            type='file'
                            onChange={handleFileUpload}
                        />
                        {
                            loading &&
                            <Form.Text muted>
                                Uploading...
                            </Form.Text>
                        }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => create()} disabled={loading}>Create</Button>
                </Modal.Footer>
            </Modal>
            <Button onClick={() => setShow(true)}>Create Version</Button>
            <br />
            <br />
            <Card>
                <Card.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Version Number</th>
                                <th>Status</th>
                                <th>Link</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {versions && versions.map((items: any, index: number) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{items.version}</td>
                                        <td>{items.type}</td>
                                        <td>
                                            {
                                                items.link ? <FaClipboard style={{ cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(items.link); toast.success("Copied to clipboard") }} /> : "N/A"
                                            }
                                        </td>
                                        <td>
                                            <ButtonGroup>
                                                <Button variant='danger' onClick={() => markObsolete(items._id)}>Mark Obsolete</Button>
                                                <Button variant='success' onClick={() => markCurrent(items._id)}>Mark Current</Button>
                                                <Button variant='warning' onClick={() => setShowUpdate(items)}>Update File</Button>
                                            </ButtonGroup>

                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>


            <Modal show={showUpdate} onHide={() => setShowUpdate(false)}>
                <Modal.Header>
                    <Modal.Title>Update File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Version Number</Form.Label>
                        <Form.Control
                            type="text" placeholder="Enter version number"
                            value={showUpdate?.version}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>File</Form.Label>
                        <Form.Control
                            type='file'
                            onChange={handleFileUpload}
                        />
                        {
                            loading &&
                            <Form.Text muted>
                                Uploading...
                            </Form.Text>
                        }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => update()} disabled={loading}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}