import moment from 'moment';
import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import AdminService from '../../services/admin.service';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';


interface IProps {
    show: boolean,
    onHide: () => void,
}

export default function TimelyInvigilatorsModal({ show, onHide }: IProps) {
    const [data, setData] = useState<any>(null);
    const params = useParams();

    function handleChangeData(e: any) {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    function onModalClose() {
        setData(null);
        onHide();
    }

    async function handleSubmit() {
        if (!data?.startTime || !data?.endTime) {
            toast.error("Select both start time and end time");
            return;
        }
        await AdminService.timelyInvigilators(params.examId, params.shiftId, moment(data?.startTime)?.toISOString(), moment(data?.endTime)?.toISOString())
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message || "Request Sent, check back later!");
                    onModalClose();
                }
            })
            .catch(e => {
                console.log(e);
                toast.error(e.response?.data?.message || e?.response?.data || "Something went wrong");
            })
    }
    return (
        <>
            <Modal show={show} onHide={onModalClose}>
                <Modal.Body>
                    <Row className="mt-2">
                        <Col md>
                            <label className='fw-bold xcn-text-12 text-secondary'>Start Time</label>
                            <DatePicker
                                dateFormat="yyyy/MM/dd h:mm aa"
                                showTimeSelect
                                selected={moment(data?.startTime).toDate()}
                                name="startTime"
                                onChange={(e) => { handleChangeData({ target: { name: "startTime", value: e } }) }}
                                className="form-control xcn-text-12"
                            />
                        </Col>
                        <Col md>
                            <label className='fw-bold xcn-text-12 text-secondary'>End Time</label>
                            <DatePicker
                                dateFormat="yyyy/MM/dd h:mm aa"
                                showTimeSelect
                                name="endTime"
                                selected={moment(data?.endTime).toDate()}
                                onChange={(e) => { handleChangeData({ target: { name: "endTime", value: e } }) }}
                                className="form-control xcn-text-12"
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleSubmit} >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}